<template>
  <div class="about-container">
    四川群峰环保科技有限公司是四川至美环境管理有限公司全资子公司，专注于智能生活垃圾分类运营服务，为学校、居民小区、写字楼、企（事）业单位等提供一整套生活垃圾分类收集、分类运输、分类处置的创新运营模式。公司拥有年轻化、专业化的运营服务团队，运用“互联网+”思维打造完美的用户体验，通过专场活动、上门宣传活动、现场精准指导、投放积分返利等多种方式引导各个年龄层次的居民参与到垃圾分类实践活动中。通过我们的努力培养每一位居民养成生活垃圾分类良好习惯，为用户提供减量数据，提高居民参与垃圾分类工作的知晓率、参与率和精准投放率，让垃圾分类成为一种新时尚，最终实现生活垃圾真正源头减量。
  </div>
</template>

<script>

export default {
  name: 'AboutInfo',
  data () {
    return {

    }
  },
  methods: {

  }

}

</script>

<style lang="scss" scoped>

.about-container {
  flex: 1;
  font-size: 16px;
  font-family: Microsoft YaHei;
  line-height: 34px;
  color: #4E4E4E;
  text-indent: 2rem;
  text-align: left;
}

</style>
